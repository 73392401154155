import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-617ef87c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "check-container" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkbox", _ctx.checked ? 'default-checked' : 'default-unchecked']),
    style: _normalizeStyle({ 'background-color': _ctx.checked ? _ctx.enabledColor : '', 'border-color': _ctx.checked ? _ctx.enabledColor : '' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "check",
        innerHTML: _ctx.iLib.getIcon(_ctx.Icons.CHECK_MARK, 20, 20, 2)
      }, null, 8, _hoisted_2)
    ])
  ], 6))
}